import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./screens/dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./screens/Login";
import Company from "./screens/CompanyManagement/Company";
import Driver from "./screens/DriverManagement/Driver";
import MyRating from "./screens/Ratee/MyRating";
import MyContext from './components/context/MyContext'
import { useState } from "react";
import 'react-responsive-modal/styles.css';


function App() {

  const [agentLogin, setAgentLogin] = useState(false)

  return (
    <>
      <ToastContainer />

      <MyContext.Provider value={{setAgentLogin:setAgentLogin}}>
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="*" element={<Navigate to="/" />} />

        {(localStorage.getItem('adminLogin') || agentLogin)?
        <>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/company" element={<Company />} />
        <Route path="/driver" element={<Driver />} />
        <Route path="/rating/:id" element={<MyRating />} />
        </>
        :null}
      </Routes>
      </MyContext.Provider>
    </>
  );
}

export default App;
