import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import Chart from 'react-apexcharts'
import { MdBuild, MdSupport } from "react-icons/md";
import { FaBuildingUser, FaUsers } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
const Dashboard = () => {
  const [company, setCompany] = useState([]);
  const [individualDriver, setIndividualDriver] = useState([]);
  const [companyDriver, setCompanyDriver] = useState([]);
  const [visitor, setVisitor] = useState(null);

  useEffect(() => {
    axios
      .get(`${Base_url}/company/get`)
      .then((res) => {
        console.log(res);
        setCompany(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${Base_url}/visitor/get`)
      .then((res) => {
        console.log(res.data);
        setVisitor(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${Base_url}/driver/get?type=individual`)
      .then((res) => {
        console.log(res);
        setIndividualDriver(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${Base_url}/driver/get?type=company`)
      .then((res) => {
        console.log(res);
        setCompanyDriver(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);






  const options = {
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'string',
      categories: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    },
    tooltip: {
      x: {
        format: 'MMM'
      },
    },
  }


console.log(visitor?.January);


  const series = [{
    name: 'Visitors',
    color: '#7FB5E2',
    data: visitor?[visitor?.January, visitor?.February, visitor?.March,  visitor?.April, visitor?.May, visitor?.June, visitor?.July, visitor?.August, visitor?.September, visitor?.October, visitor?.November, visitor?.December]:[0,0,0,0,0,0,0,0,0,0,0,0]
  }

]











  return (
    <Wrapper>
      <h2 className="main_title">Dashboard</h2>

      <section>
        <div className=" py-4  w-full grid  grid-cols-1  xl:grid-cols-3 md:grid-cols-2 gap-5">
          <div className=" w-full">
            <div className=" p-5 rounded-lg flex  justify-between" id="mainbg">
              <div>
                <h2 className=" text-white font-bold">Total Companies</h2>
                <div className=" pt-2 flex items-center gap-3">
                  <p className="text-4xl text-white">{company?.length}</p>
                </div>
              </div>
              <div>
                <FaBuilding size={80} color="white" />
              </div>
            </div>
          </div>
          <div className=" w-full">
            <div className=" flex justify-between p-5 rounded-lg" id="mainbg">
              <div>
                <h2 className=" text-white font-bold">Company Drivers</h2>
                <div className=" pt-2 flex items-center gap-3">
                  <p className="text-4xl text-white">{companyDriver?.length}</p>
                </div>
              </div>
              <div>
                <FaBuildingUser size={80} color="white" />
              </div>
            </div>
          </div>
          <div className=" w-full">
            <div className=" flex justify-between p-5 rounded-lg" id="mainbg">
              <div>
                <h2 className=" text-white font-bold">Individual Drivers</h2>
                <div className=" pt-2 flex items-center gap-3">
                  <p className="text-4xl text-white">{individualDriver?.length}</p>
                </div>
              </div>
              <div>
                <MdSupport size={80} color="white" />
              </div>
            </div>
          </div>


        </div>
      </section>




      <div className="w-full mt-3">
        <h2 className="main_title">Total Visitors</h2>
        <Chart options={options} series={series} type="area" height={380} width={'100%'} />
      </div>



    </Wrapper>
  );
};

export default Dashboard;
