import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Modal from "../../components/modal";
import { MdClose } from "react-icons/md";
import { IoImageOutline } from "react-icons/io5";
import ReactSelect from "react-select";

const AddDriver = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  setUsers,
  setType,
  setMyStar
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [companyCode, setCompanyCode] = useState(null);
  const [gender, setGender] = useState(null)
  console.log(selectedImage);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setSelectedImages(file)

  };

  const [loading, setLoading] = useState(false);



  const SubmitData = async (values) => {

    if (values.firstName.value.length === 0) {
      toast.error("Please Enter First Name!");
    }
    else if (values.lastName.value.length === 0) {
      toast.error("Please Enter Last Name!");
    }
    else if (values.password.value.length === 0) {
      toast.error("Please Enter Password!");
    }
    else if (values.cpassword.value.length === 0) {
      toast.error("Please Enter Confirm Password!");
    }
    else if (values.password.value !== values.cpassword.value) {
      toast.error("Password and confirm password must be same!");
    }
    else if (values.email.value.length === 0) {
      toast.error("Please Enter Email!");
    }
    else if (values.phone.value.length === 0) {
      toast.error("Please Enter Phone Number!");
    }
    else if (values.carNumber.value.length === 0) {
      toast.error("Please Enter Car Plate Number!");
    }
    else if (values.baseRate.value.length === 0) {
      toast.error("Please Enter Base Rate!");
    }
    else if (values.costPerMile.value.length === 0) {
      toast.error("Please Enter Cost Per Mile!");
    }
    else if (!gender) {
      toast.error("Please Select Gender!");
    }
    else if (selectedOption !== null && companyCode === null) {
      toast.error("Please Enter Company Code!");
    }
    else if (selectedOption !== null && selectedOption?.value?.code !== companyCode) {
      toast.error("Please Enter Correct Company Code!");
    }

    else {
      setLoading(true)


      let param = new FormData();
      if (selectedImages) {
        param.append("image", selectedImages);
      }
      param.append("firstName", values.firstName.value);
      param.append("lastName", values.lastName.value);
      param.append("email", values.email.value);
      param.append("phone", values.phone.value);
      param.append("password", values.password.value);
      param.append("gender", gender);
      param.append("carPlate", values.carNumber.value);

      if (values.carType.value.length > 0) {
        param.append("carType", values.carType.value);
      }

      if (values.costPerMinute.value.length > 0) {
        param.append("costPerMinute", values.costPerMinute.value);
      }
      param.append("baseRate", values.baseRate.value);
      param.append("costPerMile", values.costPerMile.value);

      let mytype = ''

      if (selectedOption) {
        param.append("companyId", selectedOption?.value?._id);
        if (!companyCode) {
          toast.error('Please Enter Company Code!');
        }
        else {
          param.append("companyCode", selectedOption?.value?.code);
        }
        param.append("type", 'company');
        setType('company')
        mytype = 'company'
      }
      else {
        param.append("type", 'individual');
        setType('individual')
        mytype = 'individual'
      }



      await axios
        .post(`${Base_url}/driver/create`, param)
        .then((res) => {
          console.log(res.data);

          if (res.data.status === 'ok') {
            toast.success('Data created successfully!');
            setIsModalOpen(false);
            setLoading(false)
            axios
              .get(`${Base_url}/driver/get?type=${mytype}`)
              .then((res) => {
                console.log(res.data);
                setSelectedImages(null)
                setSelectedImage(null)
                setUsers(res.data.data);
                setMyStar(res.data)
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          toast.error(error);

          setLoading(false)
        });
    }
  };





  const [options, setOptions] = useState([]);
  const [company, setCompany] = useState([]);


  useEffect(() => {
    axios
      .get(`${Base_url}/company/get`)
      .then((res) => {
        console.log(res.data);
        setCompany(res.data.data);

        // Map through the data and set the options state
        const newOptions = res.data?.data?.map((i) => ({
          value: i,
          label: i.name,
        }));

        setOptions(newOptions); // Update options state

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize h4 font-semibold">Add Driver</h1>
            <MdClose onClick={() => setIsModalOpen(false)} size={25} />
          </div>
          <hr />
          <div className=" p-5">
            <div className=" text-center my-2">
              {selectedImages ? (
                <img
                  src={URL.createObjectURL(selectedImages)}
                  className="mx-auto  w-28  h-28  rounded-xl"
                  alt=""
                />
              ) : (
                <>

                  <div className="mx-auto flex justify-center items-center  bg-gray-100 w-28  h-28  rounded-xl">
                    <IoImageOutline className=" text-primary" size={60} />
                  </div>
                </>
              )}

              <div className="  my-5">
                <label
                  htmlFor="fileInput"
                  className="px-12 py-2 bg-white  font-semibold text-primary border   border-gray-200 rounded-lg cursor-pointer"
                >
                  Browse File
                </label>
                <input
                  accept="image/*"
                  onChange={handleFileChange}
                  name="profileImage"
                  type="file"
                  id="fileInput"
                  className="hidden"
                />
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                SubmitData(e.target);
              }}
            >
              <div className=" flex gap-5 flex-wrap">

                <div className=" md:w-[48%] w-[100%]">
                  <label className=" block mb-2 text-sm font-medium  text-gray-900">Company Name</label>
                  <ReactSelect
                    defaultValue={selectedOption}
                    onChange={(e) => {
                      if (e?.value) {
                        setSelectedOption(e)
                      }
                      else {
                        setSelectedOption(null);
                      }
                    }}
                    options={[{ value: null, label: 'Select Company Name' }, ...options]}
                    styles={{ backgroundColor: 'transparent' }}
                    className="  w-full bg-lightGray h-13 rounded-md border"
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Company Code"}
                    placeholder={""}
                    name={"companyCode ccode"}
                    className={"border  w-full  py-3"}
                    defaultValue={companyCode}
                    id={'ccode'}
                    onChange={(e) => { setCompanyCode(e.target.value) }}
                    onBlur={() => {
                      if (selectedOption?.value?.code !== companyCode && companyCode) {
                        setCompanyCode(null);
                        toast.warning('Please enter a correct company code')
                      }
                    }}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"First Name"}
                    placeholder={""}
                    name={"firstName"}
                    className={"border  w-full  py-3"}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Last Name"}
                    placeholder={""}
                    name={"lastName"}
                    className={"border  w-full  py-3"}
                  />
                </div>
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Email"}
                    placeholder={""}
                    name={"email"}
                    className={"border  w-full  py-3"}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Phone"}
                    placeholder={""}
                    name={"phone"}
                    className={"border  w-full  py-3"}
                    type={'text'}
                    onKeyDown={(e) => {
                      if (!/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Password"}
                    placeholder={""}
                    name={"password"}
                    className={"border  w-full  py-3"}
                  />
                </div>
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Confirm Password"}
                    placeholder={""}
                    name={"cpassword"}
                    className={"border  w-full  py-3"}
                  />
                </div>
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Car Plate Number"}
                    placeholder={""}
                    name={"carNumber"}
                    className={"border  w-full  py-3"}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Car Type"}
                    placeholder={""}
                    name={"carType"}
                    className={"border  w-full  py-3"}
                  />
                </div>


                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Base Rate"}
                    placeholder={""}
                    name={"baseRate"}
                    className={"border  w-full  py-3"}
                    type={'number'}
                    step={0.5}
                    min={0}
                  />
                </div>


                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Cost Per Mile"}
                    placeholder={""}
                    name={"costPerMile"}
                    className={"border  w-full  py-3"}
                    type={'number'}
                    step={0.01}
                    min={0}
                  />
                </div>


                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Cost Per Minute"}
                    placeholder={""}
                    name={"costPerMinute"}
                    className={"border  w-full  py-3"}
                    type={'number'}
                    step={0.05}
                    min={0}
                  />
                </div>


                <div className=" md:w-[100%] w-[100%]">
                  <label className=" block mb-2 text-sm font-medium  text-gray-900">Gender</label>
                  <div className="flex flex-column flex-md-row gap-5">
                    <div className="flex gap-3" onClick={() => {
                      setGender('Male')
                    }} >
                      <input className="form-check-input" type="radio" name="gender" id="male" value={'Male'} />
                      <label className="form-check-label" htmlFor="male">Male</label>
                    </div>
                    <div className="flex gap-3" onClick={() => {
                      setGender('Female')
                    }}>
                      <input className="form-check-input" type="radio" name="gender" id="female" value={'Female'} />
                      <label className="form-check-label" htmlFor="female">Female</label>
                    </div>
                    <div className="flex gap-3" onClick={() => {
                      setGender('Prefer not to say')
                    }}>
                      <input className="form-check-input" type="radio" name="gender" id="prefer-not-to-say"
                        value={'Prefer not to say'} />
                      <label className="form-check-label" htmlFor="prefer-not-to-say">Prefer not to say</label>
                    </div>
                  </div>
                </div>


              </div>
              {loading === true ? <button
                disabled
                type="button"
                class="w-full h-11 bg-primary border-none outline-none  rounded-lg mt-4 shadow-sm cursor-pointer text-lg text-white font-semibold"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 me-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </button> : <Button
                label={"save"}
                type={"submit"}
                className={
                  "   bg-primary mt-3 uppercase text-white py-2  w-full"
                }
              />}

            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddDriver;
