import React, { useEffect, useState } from "react";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import moment from 'moment'
import Wrapper from "../Wrapper";
import { Rating } from 'react-simple-star-rating'
import { FaStar } from "react-icons/fa";
import './MyRate.css'
import Modal from 'react-responsive-modal';
import { toast } from "react-toastify";


const MyRating = () => {


  const [myStar, setMyStar] = useState(null)

  const navigate = useNavigate()
  const { id } = useParams();




  const [rateList, setRateList] = useState([]);


  useEffect(() => {
    axios
      .get(`${Base_url}/rating/getDriver/${id}`)
      .then((res) => {
        console.log(res.data);
        setRateList(res.data.data);
        setMyStar(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);




  const onsubmit = async (page) => {
    axios
      .get(`${Base_url}/rating/getDriver/${id}?page=${page}`)
      .then((res) => {
        console.log(res.data);
        setMyStar(res.data);
        setRateList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }







  const removeFunction = (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/rating/delete/${delId}`)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your data has been deleted.", "success");

              axios
                .get(`${Base_url}/rating/getDriver/${id}`)
                .then((res) => {
                  console.log(res.data);
                  setMyStar(res.data);
                  setRateList(res.data.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };




  const [rateModal, setRateModal] = useState(false)
  const [selRate, setSelRate] = useState(null)
  const [load, setLoad] = useState(false)


  const [rating, setRating] = useState(null)
  const [review, setReview] = useState(null)

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)
  }




  const submit = async () => {
    setLoad(true)
    const param = {
      driverId: id,
      star: rating,
      review: review
    }

    await axios
      .put(`${Base_url}/rating/update/${selRate?._id}`, param)
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          toast.success("Rating Updated Successfully!");
          setRating(null)
          setReview(null)
          setLoad(false)


          axios
          .get(`${Base_url}/rating/getDriver/${id}`)
          .then((res) => {
            console.log(res.data);
            setMyStar(res.data);
            setRateList(res.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
          setRateModal(false)

        }
      })
      .catch((error) => {
        toast.error(error);
        setLoad(false)
      });

  };



  return (<Wrapper>
    <div className="md:flex flex justify-center items-center flex-col pb-5 w-full">


      {rateList.length > 0 ? (
        <>
          <div className="mt-5 p-4 mb-5 rounded-lg w-full md:w-3/4 lg:w-3/4 shadow bg-gray-50">
            <div className="flex flex-wrap md:flex-nowrap">
              <div className="md:w-1/3 flex flex-col mb-3">
                <div className="rating-box">
                  <h1 className="pt-4">{myStar?.averageRating}.0</h1>
                  <p>out of 5</p>
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        color={index < parseInt(myStar?.averageRating) ? 'orange' : '#d3d3d3'} size={20}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="md:w-2/3">
                <div className="rating-bar0">
                  <div className="mx-auto">
                    {[
                      { label: "Excellent", stars: myStar?.star5 },
                      { label: "Good", stars: myStar?.star4 },
                      { label: "Average", stars: myStar?.star3 },
                      { label: "Poor", stars: myStar?.star2 },
                      { label: "Terrible", stars: myStar?.star1 },
                    ].map((rating, index) => (
                      <div className="flex w-full mb-2" key={index}>
                        <div className="w-1/4">
                          <div className="rating-label">{rating.label}</div>
                        </div>
                        <div className="w-3/4 flex items-center">
                          <div className="rating-bar">
                            <ProgressBar completed={(parseInt(rating.stars) / parseInt(myStar?.count)) * 100} bgColor="#FBC02D" height={14} width={240} customLabel=" " />
                          </div>
                          <div className="ml-2">{rating.stars}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            {rateList?.map((i) => {
              return (<div className="p-4 mb-5 lg:mx-3 md:mx-0 rounded-lg shadow bg-gray-50 w-100 ">
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                  <div></div>
                  <div className="flex gap-2">

                    <img onClick={() => {
                      setSelRate(i)
                      setRating(i?.rate)
                      setReview(i?.review)
                      setRateModal(true)

                    }} src={require('../../assets/image/edit.png')} alt="" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />



                    <img onClick={() => removeFunction(i._id)} src={require('../../assets/image/del.png')} alt="" style={{ width: '20px', height: '20px', cursor: 'pointer', alignSelf: 'flex-end' }} />



                  </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                  <div className="flex items-center gap-3">
                    <p className="m-0 mt-1">
                      <span className="text-gray">{i?.star}.0</span>
                    </p>
                    <div className="flex items-center gap-2">
                      {[...Array(5)].map((_, index) => (
                        <FaStar
                          key={index}
                          color={index < parseInt(i?.star) ? 'orange' : '#d3d3d3'} size={20}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="ml-auto">
                    <p className="text-gray">{moment(i?.createdAt).format("DD MMM YYYY")}</p>
                  </div>
                </div>
                <div className="text-left">
                  <h4 className="text-blue-500 mt-3 text-lg ">{i?.review}</h4>
                </div>
              </div>
              )
            })}
          </div>

          <div className="lg:flex items-center p-4 w-full ">
            <div className="md:mr-auto text-center md:text-left text-black mt-2 mb-2">
              Showing {parseInt(myStar?.page)} to {myStar?.limit} of {myStar?.count} entries
            </div>
            <ul className="pagination mb-0 flex-wrap justify-center flex gap-3">
              {parseInt(myStar?.page) > 1 ? (
                <li className="mb-1">
                  <div className="page-link cursor-pointer" onClick={() => {
                    navigate(`?page=${parseInt(myStar?.page) - 1}`)
                    onsubmit(parseInt(myStar?.page) - 1)
                  }}>
                    Previous
                  </div>
                </li>
              ) : (
                <li className="mb-1 opacity-50">
                  <div className="page-link">Previous</div>
                </li>
              )}

              {[...Array(myStar?.totalPages).keys()].map(i => (
                <li key={i + 1} className={`mb-1 ${i + 1 === parseInt(myStar?.page) ? 'bg-blue-500 text-white' : ''}`}>
                  <div className={`page-link cursor-pointer px-3 py-1 rounded ${i + 1 === parseInt(myStar?.page) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900 hover:bg-gray-200'}`} onClick={() => {
                    navigate(`?page=${i + 1}`)
                    onsubmit(i + 1)
                  }}>
                    {i + 1}
                  </div>
                </li>
              ))}

              {parseInt(myStar?.page) < myStar?.totalPages ? (
                <li className="mb-1">
                  <div className="page-link cursor-pointer" onClick={() => {
                    navigate(`?page=${parseInt(myStar?.page) + 1}`)
                    onsubmit(parseInt(myStar?.page) + 1)
                  }}>
                    Next
                  </div>
                </li>
              ) : (
                <li className="mb-1 opacity-50">
                  <div className="page-link">Next</div>
                </li>
              )}
            </ul>
          </div>
        </>
      ) : (
        <h4 className="text-dark text-center">No rating found!</h4>
      )}
    </div>







    <Modal open={rateModal} onClose={() => {
      setRateModal(false)
    }} center classNames=''>

      <div className=" flex justify-center items-center">
        <div className="py-4  px-1 px-md-5  ">
          <h2 className="font-bold text-center text-3xl">Rate Now</h2>
          <p className="text-center px-5">Please rate or give reviews to this driver in order to find best driver!</p>

          <div className="flex justify-center  items-center gap-2 my-4">
            {[...Array(5)].map((_, index) => (
              <FaStar
                key={index}
                color={index < parseInt(rating ? rating : selRate?.star) ? 'orange' : '#d3d3d3'} size={40} onClick={() => {
                  setRating(index + 1)
                }}
              />
            ))}
          </div>

          <div className="w-100 flex justify-center  items-center">
            <textarea placeholder='Enter your reviews here...' className='w-100 border p-2' defaultValue={selRate?.review} onChange={(e) => { setReview(e.target.value?e.target.value:selRate?.review) }} cols={55}></textarea>
          </div>


          <div className="mt-4 flex justify-center  items-center ">


            <button id="btn" className=" bg-primary mt-3 uppercase text-white py-2  w-96" onClick={() => {
              submit()
            }} disabled={load}>{load?'Please wait...':'Submit'}</button>
          </div>

        </div>
      </div>

    </Modal>




  </Wrapper>

  );
};

export default MyRating;
