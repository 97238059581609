import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MyContext from '../../components/context/MyContext'

const AdminLogin = () => {

    const navigate = useNavigate()
    const [email, setEmail]=useState(null)
    const [password, setPassword]=useState(null)
    const { setAgentLogin } = useContext(MyContext)


  return (
    <div id="mainbg" className=" h-screen flex justify-center items-center">
      <form className=" bg-white shadow-2xl p-5 w-10/12 sm:w-8/12 md:w-6/12 lg:w-4/12 rounded">
        <div className=" text-center">
          <img
            src={require("../../assets/image/logo1.jpg")}
            className=" w-[8rem] mx-auto"
            alt=""
          />
          <h3 className="my-4 fancy-text">METACAB</h3>
        </div>

        <div className="mb-4 mt-4">
          <label className=" text-black font-normal py-3">Username</label>
          <input
            type="email"
            name="email"
            className="w-full  border-primary border   bg-white mt-2 p-4 rounded outline-none text-black"
            placeholder="Enter email..."
            onChange={(e)=>{setEmail(e.target.value)}}
          />
        </div>
        <div className="mb-4">
          <label className=" text-black font-normal py-3">Password</label>
          <input
            type="password"
            name="password"
            className="w-full mt-2  border-primary border  bg-white  p-4 rounded outline-none text-black"
            placeholder="Enter password..."
            onChange={(e)=>{setPassword(e.target.value)}}
          />
        </div>
        <div className="mb-4">
          <div
            to={"/dashboard"}

            onClick={()=>{
              if(email==="admin@gmail.com" && password==="admin123"){
                
                setAgentLogin(true)
                navigate("/dashboard")
                localStorage.setItem('adminLogin',true)
                toast.success("Login Successfully")
              }else{
                toast.error("Invalid credentials")
              }
            }}

            type="submit"
            value={"sign in"}
            id="mainbg"
            className="  text-center w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
          >
            sign in
          </div>
        </div>
      </form>
    </div>
  );
};
export default AdminLogin;
