import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiCoinStack } from "react-icons/bi";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IoLogoModelS } from "react-icons/io";
import { FaAngleDown, FaBuilding, FaCarSide, FaSignOutAlt } from "react-icons/fa";
import { GiHouseKeys } from "react-icons/gi";
import { MdSupport } from "react-icons/md";
const Sidebar = ({ side, closeSidebar }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuAnimation = {
    hidden: {
      opacity: 0,
      height: 0,
      padding: 0,
      transition: { duration: 0.3, when: "afterChildren" },
    },
    show: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
        when: "beforeChildren",
      },
    },
  };

const navigate=useNavigate()

  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);



  return (
    <div
      className={`fixed top-0  shadow-xl ${side} sm:left-0 w-64 h-screen  bg-white z-10 transition-all`}
    >
      <i
        className="bi bi-x-lg absolute text-black top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className="  p-5">
        <h1 className=" text-white text-4xl font-medium">
          <img
            src={require("../../assets/image/logo1.jpg")}
            className="  w-[8rem]  mx-auto"
            alt=""
          />
          <h3 className="my-4 fancy-text text-center">METACAB</h3>
          {/* Logo */}
        </h1>
      </div>

      <ul className="">
        <li className="px-4 cursor-pointer font-semibold transition-all py-3 text-black flex items-center   hover:text-white" id={isActive('/dashboard')?"mainbg":"mainbghover"} onClick={()=>{navigate('/dashboard')}}>
          <i class="bi bi-grid-fill  mr-2 inline-block text-xl"></i>
          <Link to="/dashboard" className="text-lg capitalize">
            Dashboard
          </Link>
        </li>
        

        <li className="px-4 cursor-pointer  font-semibold  transition-all py-3 text-black flex items-center  hover:text-white" id={isActive('/company')?"mainbg":"mainbghover"} onClick={()=>{navigate('/company')}}>
          {/* <i class=" mr-2 bi bi-boxes"></i> */}
          <FaBuilding className=" mr-2 text-xl"  />
          <Link to="/company" className="text-base capitalize">
          Company Management
          </Link>
        </li>


        
        <li className="px-4 cursor-pointer  font-semibold  transition-all py-3 text-black flex items-center  hover:text-white" id={isActive('/driver')?"mainbg":"mainbghover"} onClick={()=>{navigate('/driver')}}>
        <MdSupport className=" mr-2 text-xl"  />
          <Link to="/driver" className="text-base capitalize">
          Driver Management
          </Link>
        </li>
        
       
      </ul>




      <div className="px-4 cursor-pointer  font-semibold  transition-all py-3 text-white flex items-center  hover:text-white absolute bottom-5 rounded-r-full" id="mainbglog" onClick={()=>{
        navigate('/')
        localStorage.clear()
        }}>
          {/* <i class=" mr-2 bi bi-boxes"></i> */}
          <FaSignOutAlt className=" mr-2 text-xl"  />
          <Link to="/" className="text-base capitalize">
            Logout
          </Link>
        </div>



    </div>
  );
};
export default Sidebar;
