import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import Button from "../../components/Button";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import UpdateDriver from "./UpdateDriver";
import AddDriver from "./AddDriver";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import ReactSelect from "react-select";


const Driver = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('individual');
  const [myStar, setMyStar] = useState(null)

  const navigate = useNavigate()

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log(isModalOpen);

  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const UpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  console.log(isUpdateOpen);

  const [users, setUsers] = useState([]);

  const [singleUser, setSingleUser] = useState({});
  const [companyCode, setCompanyCode] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedComp, setSelectedComp] = useState('');





  useEffect(() => {
    axios
      .get(`${Base_url}/driver/get?type=${type}`)
      .then((res) => {
        console.log(res.data);
        setMyStar(res.data)
        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [type]);



  const onsubmit = async (page,search) => {
    axios
      .get(`${Base_url}/driver/get?type=${type}&page=${page}&search=${search}`)
      .then((res) => {
        console.log(res.data);
        setMyStar(res.data);
        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log(users);

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/driver/delete/${id}`)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your data has been deleted.", "success");

              axios
                .get(`${Base_url}/driver/get?type=${type}`)
                .then((res) => {
                  console.log(res.data);
                  setMyStar(res.data)
                  setUsers(res.data.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };




  const changeFunction = (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Change it!",
    }).then((result) => {
      if (result.isConfirmed) {

        const param = new FormData()
        param.append('block', status)

        axios
          .put(`${Base_url}/driver/update/${id}`, param)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {

              axios
                .get(`${Base_url}/driver/get?type=${type}`)
                .then((res) => {
                  console.log(res.data);
                  setMyStar(res.data)
                  setUsers(res.data.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };




  const [options, setOptions] = useState([]);
  const [company, setCompany] = useState([]);


  useEffect(() => {
    axios
      .get(`${Base_url}/company/get`)
      .then((res) => {
        console.log(res.data);
        setCompany(res.data.data);

        // Map through the data and set the options state
        const newOptions = res.data?.data?.map((i) => ({
          value: i?._id,
          label: i.name,
        }));

        setOptions(newOptions); // Update options state

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);








  return (
    <Wrapper>
      <div className=" flex   justify-between items-center">
        <div>
          <h2 className="main_title">Driver Management</h2>
          {/* <p className="param">Showing 8 to 20</p> */}
        </div>
        <UpdateDriver
          singleUser={singleUser}
          isModalOpen={isUpdateOpen}
          setIsModalOpen={setIsUpdateOpen}
          setUsers={setUsers}
          setType={setType}
          setMyStar={setMyStar}
          companyCode={companyCode}
          setCompanyCode={setCompanyCode}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <AddDriver
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setUsers={setUsers}
          setType={setType}
          setMyStar={setMyStar}
        />
        <div>
          <Button
            className={"  bg-primary py-3"}
            label={`Add`}
            onClick={openModal}
          />
        </div>
      </div>

      <section className="mb-20 mt-7 text-gray-800">

        <div className="flex justify-between items-center flex-col md:flex-row mb-3">
          <div className="flex gap-4">
            <div>
              <Button
                className={"  bg-primary py-3 mb-3"}
                label={`Individual Drivers`}
                onClick={() => { 
                  setType('individual')
                  setSelectedComp('')
                 }}
                id={type === 'individual' ? "mainbg" : "mainbglogs"}
              />
            </div>
            <div>
              <Button
                className={"  bg-primary py-3 mb-3"}
                label={`Company Drivers`}
                onClick={() => { 
                  setType('company')
                  setSelectedComp('')
                 }}
                id={type === 'company' ? "mainbg" : "mainbglogs"}
              />
            </div>

          </div>

          {type==='company'?<div className=" md:w-[40%] w-[100%] flex items-center gap-3">
            <ReactSelect
              defaultValue={selectedComp}
              onChange={(e) => {
                if (e?.value) {
                  setSelectedOption(e?.label)
                }
                else{
                  setSelectedOption(null);
                }
              }}
              options={[{ value: null, label: 'Select Company Name' }, ...options]}
              styles={{ backgroundColor: 'transparent' }}
              className="  w-full bg-lightGray h-13 rounded-md border"
              placeholder={'Enter company name'}
            />
            <button id="btn" className=" bg-primary  uppercase text-white py-2  px-2 rounded-md " onClick={() => {
              onsubmit(1,selectedComp)
            }} >{'search'}</button>
          </div>:null}
        </div>

        <div className="block  rounded-lg shadow-lg bg-white">
          <div className="flex flex-col overflow-x-auto">
            <div className="">
              <div className="inline-block min-w-full ">
                <div className="overflow-hidden w-100">
                  <table className="min-w-full mb-0">
                    <thead className="w-full " id="mainbg">
                      <tr className=" rounded-lg  whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          No
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          Full Name
                        </th>

                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          className="text-sm text-white   font-bold px-6 py-4"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Phone
                        </th>
                        {type === 'company' ? <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Company Name
                        </th> : null}

                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Rating
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Status
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Action
                        </th>


                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >

                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {users?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t  rounded-md">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <div className="flex flex-row items-center">
                                  <p className="mb-0.5 font-medium text-black">
                                    {index + 1}
                                  </p>
                                </div>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.firstName + " " + item?.lastName}
                                </span>
                              </td>
                              <td className="text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <img
                                  className="rounded-full w-12"
                                  src={item?.image ? item?.image : 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                                  alt="Avatar"
                                />
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.email}
                                </span>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.phone}
                                </span>
                              </td>
                              {type === 'company' ? <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.companyId?.name}
                                </span>
                              </td> : null}


                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.rating ? item?.rating : 0}/5
                                </span>
                              </td>

                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                {item?.block ? <span className="  text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-rose-600 text-sm ">
                                  {item?.block ? 'Block' : 'Active'}
                                </span> : <span className="  text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-blue-500 text-sm ">
                                  {item?.block ? 'Block' : 'Active'}
                                </span>}
                              </td>

                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-center  flex gap-2" colspan="2">


                                <img onClick={() => { changeFunction(item?._id, !item?.block) }} src={'https://www.freeiconspng.com/thumbs/refresh-icon/refresh-icon-30.png'} alt="" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />


                                <img onClick={() => { navigate(`/rating/${item._id}`) }} src={'https://www.pngplay.com/wp-content/uploads/8/Gold-Vector-Star-PNG-Clipart-Background.png'} alt="" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />


                                <img onClick={() => {
                                  setCompanyCode(item?.companyId?.code)
                                  setSelectedOption({ value: item?.companyId, label: item?.companyId?.name })
                                  setSingleUser(item)
                                  UpdateModal()

                                }} src={require('../../assets/image/edit.png')} alt="" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />

                                <img onClick={() => removeFunction(item._id)} src={require('../../assets/image/del.png')} alt="" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />



                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



          </div>
        </div>




        <div className="lg:flex items-center p-4">
          <div className="lg:mr-auto text-center lg:text-left mt-2 lg:mt-0 mb-2 lg:mb-0">
            Showing {parseInt(myStar?.page)} to {myStar?.limit} of {myStar?.count} entries
          </div>
          <ul className="pagination mb-0 justify-center lg:justify-start flex-wrap space-x-2 flex">
            {parseInt(myStar?.page) > 1 ? (
              <li className="mb-1">
                <div
                  className="cursor-pointer bg-gray-100 text-gray-900 px-3 py-1 rounded hover:bg-gray-200"
                  onClick={() => {
                    navigate(`?page=${parseInt(myStar?.page) - 1}&search=${selectedComp}`)
                    onsubmit(parseInt(myStar?.page) - 1,selectedComp)
                  }}
                >
                  Previous
                </div>
              </li>
            ) : (
              <li className="mb-1 opacity-50">
                <div className="bg-gray-100 text-gray-500 px-3 py-1 rounded">Previous</div>
              </li>
            )}

            {[...Array(myStar?.totalPages).keys()].map(i => (
              <li key={i + 1} className={`mb-1 ${i + 1 === parseInt(myStar?.page) ? 'bg-blue-500 text-white' : ''}`}>
                <div
                  className={`cursor-pointer px-3 py-1 rounded ${i + 1 === parseInt(myStar?.page) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900 hover:bg-gray-200'}`}
                  onClick={() => {
                    navigate(`?page=${i + 1}&search=${selectedComp}`)
                    onsubmit(i + 1,selectedComp)
                  }}
                >
                  {i + 1}
                </div>
              </li>
            ))}

            {parseInt(myStar?.page) < myStar?.totalPages ? (
              <li className="mb-1">
                <div
                  className="cursor-pointer bg-gray-100 text-gray-900 px-3 py-1 rounded hover:bg-gray-200"
                  onClick={() => {
                    navigate(`?page=${parseInt(myStar?.page) + 1}&search=${selectedComp}`)
                    onsubmit(parseInt(myStar?.page) + 1,selectedComp)
                  }}
                >
                  Next
                </div>
              </li>
            ) : (
              <li className="mb-1 opacity-50">
                <div className="bg-gray-100 text-gray-500 px-3 py-1 rounded">Next</div>
              </li>
            )}
          </ul>
        </div>
      </section>
    </Wrapper>
  );
};

export default Driver;
