import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Modal from "../../components/modal";
import { MdClose } from "react-icons/md";

const UpdateCompany = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  singleUser,
  setUsers,
  setMyStar
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  console.log(selectedImage);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const updateSubmit = async (values) => {
    
      
      let param = new FormData();

      if(selectedImage){
        param.append("image", selectedImage);
      }
      if(singleUser?.name!==values.name.value){
        param.append("name", values.name.value);
      }
      if(singleUser?.email!==values.email.value){
        param.append("email", values.email.value);
      }
      if(singleUser?.phone!==values.phone.value){
        param.append("phone", values.phone.value);
      }
      if(singleUser?.code!==values.code.value){
        param.append("code", values.code.value);
      }
      if(singleUser?.address!==values.address.value){
        param.append("address", values.address.value);
      }
      if(singleUser?.city!==values.city.value){
        param.append("city", values.city.value);
      }

      
      await axios
        .put(`${Base_url}/company/update/${singleUser?._id}`, param)
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            toast.success("Data Update Successfully!");
            setIsModalOpen(false);
            axios
              .get(`${Base_url}/company/get`)
              .then((res) => {
                console.log(res.data);

                setUsers(res.data.data);
                setMyStar(res.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize h4 font-semibold">Update Company</h1>
            <MdClose onClick={() => setIsModalOpen(false)} size={25} />
          </div>
          <hr />
          <div className=" p-5">
            <div className=" text-center my-2">
              {selectedImage ? (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  className="mx-auto w-28  h-28  rounded-full"
                  alt=""
                />
              ) : (
                <>
                  <img
                    src={singleUser?.image?singleUser?.image:'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                    className="mx-auto  w-28  h-28  rounded-full"
                    alt=""
                  />
                </>
              )}

              <div className="  my-5">
                <label
                  htmlFor="fileInput"
                  className="px-12 py-2 bg-white  font-semibold text-primary border   border-gray-200 rounded-lg cursor-pointer"
                >
                  Browse File
                </label>
                <input
                  accept="image/*"
                  onChange={handleFileChange}
                  name="profileImage"
                  type="file"
                  id="fileInput"
                  className="hidden"
                />
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateSubmit(e.target);
              }}
            >
              <div className=" flex gap-5 flex-wrap">
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Company Name"}
                    placeholder={""}
                    name={"name"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.name}
                  />
                </div>

                <div className="md:w-[48%] w-[100%]">
                  <Input
                    label={"Company Code"}
                    name={"code"}
                    placeholder={""}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.code}
                  />
                </div>

                <div className="md:w-[48%] w-[100%]">
                  <Input
                    label={"Company Email"}
                    placeholder={""}
                    name={"email"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.email}
                  />
                </div>

                
                <div className="md:w-[48%] w-[100%]">
                  <Input
                    label={"Company Phone"}
                    placeholder={""}
                    name={"phone"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.phone}
                    type={'text'}
                    onKeyDown={(e)=>{
                      if (!/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                
                <div className="md:w-[48%] w-[100%]">
                  <Input
                    label={"City"}
                    placeholder={""}
                    name={"city"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.city}
                  />
                </div>
                <div className="md:w-[48%] w-[100%]">
                  <Input
                    label={"Address"}
                    name={"address"}
                    placeholder={""}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.address}
                  />
                </div>
              </div>

              <Button
                label={"Update"}
                type={"submit"}
                className={
                  "  bg-[#A47ABF] mt-3 uppercase text-white py-2 w-full"
                }
              />
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateCompany;
