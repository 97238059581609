import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Modal from "../../components/modal";
import { MdClose } from "react-icons/md";
import ReactSelect from "react-select";

const UpdateDriver = ({
  isModalOpen,
  setIsModalOpen,
  closeModal,
  singleUser,
  setUsers,
  setMyStar,
  setType, companyCode, setCompanyCode, selectedOption, setSelectedOption
}) => {

  console.log(singleUser, 'ggggggggg');

  const [selectedImage, setSelectedImage] = useState(null);


  const [gender, setGender] = useState(singleUser?.gender)

  console.log(selectedImage);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const updateSubmit = async (values) => {

    console.log(selectedOption, companyCode);

    if (selectedOption && !companyCode && singleUser?.type === 'company') {
      toast.error('Please enter a company code!');
    }
    else if (selectedOption?.value?.code !== companyCode) {
      toast.error('Must enter correct company code!');
    }
    else {
      let param = new FormData();

      if (selectedImage) {
        param.append("image", selectedImage);
      }
      if (singleUser?.firstName !== values.firstName.value) {
        param.append("firstName", values.firstName.value);
      }
      if (singleUser?.lastName !== values.lastName.value) {
        param.append("lastName", values.lastName.value);
      }
      if (singleUser?.email !== values.email.value) {
        param.append("email", values.email.value);
      }
      if (singleUser?.phone !== values.phone.value) {
        param.append("phone", values.phone.value);
      }
      if (singleUser?.password !== values.password.value) {
        param.append("password", values.password.value);
      }
      if (singleUser?.carPlate !== values.carNumber.value) {
        param.append("carPlate", values.carNumber.value);
      }
      if (singleUser?.baseRate !== values.baseRate.value) {
        param.append("baseRate", values.baseRate.value);
      }
      if (singleUser?.costPerMile !== values.costPerMile.value) {
        param.append("costPerMile", values.costPerMile.value);
      }
      if (singleUser?.costPerMinute !== values.costPerMinute.value) {
        param.append("costPerMinute", values.costPerMinute.value);
      }
      if (singleUser?.carType !== values.carType.value) {
        param.append("carType", values.carType.value);
      }
      if (singleUser?.gender !== gender) {
        param.append("gender", gender ? gender : singleUser?.gender);
      }



      let mytype = ''

      if (selectedOption && singleUser?.type === 'company') {
        param.append("companyId", selectedOption?.value?._id);
        param.append("companyCode", companyCode);
        param.append("type", 'company');
        setType('company')
        mytype = 'company'
      }
      else {
        param.append("type", 'individual');
        setType('individual')
        mytype = 'individual'
      }


      await axios
        .put(`${Base_url}/driver/update/${singleUser?._id}`, param)
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            toast.success("Data Update Successfully!");
            setIsModalOpen(false);
            axios
              .get(`${Base_url}/driver/get?type=${mytype}`)
              .then((res) => {
                console.log(res.data);

                setUsers(res.data.data);
                setMyStar(res.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };








  const [options, setOptions] = useState([]);
  const [company, setCompany] = useState([]);


  useEffect(() => {
    axios
      .get(`${Base_url}/company/get`)
      .then((res) => {
        console.log(res.data);
        setCompany(res.data.data);

        // Map through the data and set the options state
        const newOptions = res.data?.data?.map((i) => ({
          value: i,
          label: i.name,
        }));


        setOptions(newOptions); // Update options state

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);




  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize h4 font-semibold">Update Driver</h1>
            <MdClose onClick={() => setIsModalOpen(false)} size={25} />
          </div>
          <hr />
          <div className=" p-5">
            <div className=" text-center my-2">
              {selectedImage ? (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  className="mx-auto w-28  h-28  rounded-full"
                  alt=""
                />
              ) : (
                <>
                  <img
                    src={singleUser?.image ? singleUser?.image : 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                    className="mx-auto  w-28  h-28  rounded-full"
                    alt=""
                  />
                </>
              )}

              <div className="  my-5">
                <label
                  htmlFor="fileInput"
                  className="px-12 py-2 bg-white  font-semibold text-primary border   border-gray-200 rounded-lg cursor-pointer"
                >
                  Browse File
                </label>
                <input
                  accept="image/*"
                  onChange={handleFileChange}
                  name="profileImage"
                  type="file"
                  id="fileInput"
                  className="hidden"
                />
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateSubmit(e.target);
              }}
            >
              <div className=" flex gap-5 flex-wrap">
                {
                  singleUser?.type === 'company' ? <>
                    <div className=" md:w-[48%] w-[100%]">
                      <label className=" block mb-2 text-sm font-medium  text-gray-900">Company Name</label>
                      {options.length > 0 ? <ReactSelect
                        defaultValue={{ value: singleUser?.companyId, label: singleUser?.companyId?.name }}
                        onChange={(e) => {
                          if (e?.value) {
                            setSelectedOption(e)
                          }
                          else {
                            setSelectedOption(null);
                          }
                        }}
                        options={[{ value: null, label: 'Select Company Name' }, ...options]}
                        styles={{ backgroundColor: 'transparent' }}
                        className="  w-full bg-lightGray h-13 rounded-md border"
                        placeholder={singleUser?.companyId?.name}

                      /> : null}
                    </div>

                    <div className=" md:w-[48%] w-[100%]">


                      <Input
                        label={"Company Code"}
                        placeholder={""}
                        name={"companyCode ccode"}
                        className={"border  w-full  py-3"}
                        defaultValue={companyCode ? companyCode : singleUser?.companyId?.code}
                        id={'ccode'}
                        onChange={(e) => { setCompanyCode(e.target.value) }}
                        onBlur={() => {
                          if (selectedOption?.value?.code !== companyCode && companyCode) {
                            setCompanyCode(null);
                            toast.warning('Please enter a correct company code')
                          }
                        }}
                      />
                    </div>

                  </> : null
                }
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"First Name"}
                    placeholder={""}
                    name={"firstName"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser.firstName}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Last Name"}
                    placeholder={""}
                    name={"lastName"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser.lastName}
                  />
                </div>
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Email"}
                    placeholder={""}
                    name={"email"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.email}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Phone"}
                    placeholder={""}
                    name={"phone"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.phone}
                    type={'text'}
                    onKeyDown={(e) => {
                      if (!/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className=" md:w-[100%] w-[100%]">
                  <Input
                    label={"Password"}
                    placeholder={""}
                    name={"password"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.password}
                  />
                </div>
                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Car Plate Number"}
                    placeholder={""}
                    name={"carNumber"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.carPlate}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Car Type"}
                    placeholder={""}
                    name={"carType"}
                    className={"border  w-full  py-3"}
                    defaultValue={singleUser?.carType}
                  />
                </div>


                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Base Rate"}
                    placeholder={""}
                    name={"baseRate"}
                    className={"border  w-full  py-3"}
                    type={'number'}
                    step={0.5}
                    min={0}
                    defaultValue={singleUser?.baseRate}
                  />
                </div>


                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Cost Per Mile"}
                    placeholder={""}
                    name={"costPerMile"}
                    className={"border  w-full  py-3"}
                    type={'number'}
                    step={0.01}
                    min={0}
                    defaultValue={singleUser?.costPerMile}
                  />
                </div>

                <div className=" md:w-[48%] w-[100%]">
                  <Input
                    label={"Cost Per Minute"}
                    placeholder={""}
                    name={"costPerMinute"}
                    className={"border  w-full  py-3"}
                    type={'number'}
                    step={0.05}
                    min={0}
                    defaultValue={singleUser?.costPerMinute}
                  />
                </div>


                <div className=" md:w-[100%] w-[100%]">
                  <label className=" block mb-2 text-sm font-medium  text-gray-900">Gender</label>
                  <div className="flex flex-column flex-md-row gap-5">
                    <div className="flex gap-3" onClick={() => {
                      setGender('Male')
                    }} >
                      <input className="form-check-input" type="radio" name="gender" id="male" value={'Male'} defaultChecked={singleUser?.gender === 'Male' ? true : false} />
                      <label className="form-check-label" htmlFor="male">Male</label>
                    </div>
                    <div className="flex gap-3" onClick={() => {
                      setGender('Female')
                    }}>
                      <input className="form-check-input" type="radio" name="gender" id="female" value={'Female'} defaultChecked={singleUser?.gender === 'Female' ? true : false} />
                      <label className="form-check-label" htmlFor="female">Female</label>
                    </div>
                    <div className="flex gap-3" onClick={() => {
                      setGender('Prefer not to say')
                    }}>
                      <input className="form-check-input" type="radio" name="gender" id="prefer-not-to-say"
                        value={'Prefer not to say'} defaultChecked={singleUser?.gender === 'Prefer not to say' ? true : false} />
                      <label className="form-check-label" htmlFor="prefer-not-to-say">Prefer not to say</label>
                    </div>
                  </div>
                </div>


              </div>

              <Button
                label={"Update"}
                type={"submit"}
                className={
                  "  bg-[#A47ABF] mt-3 uppercase text-white py-2 w-full"
                }
              />

            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateDriver;
