import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import Button from "../../components/Button";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import UpdateCompany from "./UpdateCompany";
import AddCompany from "./AddCompany";
import { useNavigate } from "react-router-dom";


const Company = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [myStar, setMyStar] = useState(null)

  const navigate=useNavigate()

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log(isModalOpen);

  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const UpdateModal = () => {
    setIsUpdateOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateOpen(false);
  };

  console.log(isUpdateOpen);

  const [users, setUsers] = useState([]);

  const [singleUser, setSingleUser] = useState({});

  useEffect(() => {
    axios
      .get(`${Base_url}/company/get`)
      .then((res) => {
        console.log(res.data);

        setUsers(res.data.data);
        setMyStar(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  
  const onsubmit = async(page)=>{
    axios
      .get(`${Base_url}/company/get?page=${page}`)
      .then((res) => {
        console.log(res.data);
        setMyStar(res.data);
        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log(users);

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/company/delete/${id}`)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your data has been deleted.", "success");

              axios
                .get(`${Base_url}/company/get`)
                .then((res) => {
                  console.log(res.data);

                  setUsers(res.data.data);
                  setMyStar(res.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <Wrapper>
      <div className=" flex   justify-between items-center">
        <div>
          <h2 className="main_title">Company Management</h2>
          {/* <p className="param">Showing 8 to 20</p> */}
        </div>
        <UpdateCompany
          singleUser={singleUser}
          isModalOpen={isUpdateOpen}
          setIsModalOpen={setIsUpdateOpen}
          setUsers={setUsers}
          setMyStar={setMyStar}
        />
        <AddCompany
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setUsers={setUsers}
          setMyStar={setMyStar}
        />
        <div>
          <Button
            className={"  bg-primary py-3"}
            label={`Add`}
            onClick={openModal}
          />
        </div>
      </div>

      <section className="mb-20 mt-7 text-gray-800">
        <div className="block  rounded-lg shadow-lg bg-white">
          <div className="flex flex-col overflow-x-auto">
            <div className="">
              <div className="inline-block min-w-full ">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" " id="mainbg">
                      <tr className=" rounded-lg  whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          No
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          Name
                        </th>

                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          className="text-sm text-white   font-bold px-6 py-4"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          City
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Code
                        </th>
                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Rating
                        </th>


                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Action
                        </th>


                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {users?.map((item, index) => {
                        return (
                          <>
                            <tr className="bg-white border-t  rounded-md">
                              <th
                                scope="row"
                                className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                              >
                                <div className="flex flex-row items-center">
                                  <p className="mb-0.5 font-medium text-black">
                                    {index + 1}
                                  </p>
                                </div>
                              </th>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.name}
                                </span>
                              </td>
                              <td className="text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <img
                                  className="rounded-full w-12"
                                  src={item?.image?item?.image:'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'}
                                  alt="Avatar"
                                />
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.email}
                                </span>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.phone}
                                </span>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.city}
                                </span>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.code}
                                </span>
                              </td>
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                <span className=" text-base text-black  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline   bg-green-200  rounded-full">
                                  {item?.rating?parseInt(item?.rating):0}/5
                                </span>
                              </td>
                              
                              <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap text-left" colSpan={"2"}>
                                <div className=" flex gap-2">
                                  
                                    <div>
                                    <img  onClick={() => {
                                      setSingleUser(item)
                                      UpdateModal()
                                      
                                      }} src={require('../../assets/image/edit.png')} alt=""  />
                                    </div>
                                  
                                    <div>
                                    <img  onClick={() => removeFunction(item._id)} src={require('../../assets/image/del.png')} alt=""  />
                                    </div>
                                 
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="lg:flex items-center p-4">
          <div className="lg:mr-auto text-center lg:text-left mt-2 lg:mt-0 mb-2 lg:mb-0">
            Showing {parseInt(myStar?.page)} to {myStar?.limit} of {myStar?.count} entries
          </div>
          <ul className="pagination mb-0 justify-center lg:justify-start flex-wrap space-x-2 flex">
            {parseInt(myStar?.page) > 1 ? (
              <li className="mb-1">
                <div
                  className="cursor-pointer bg-gray-100 text-gray-900 px-3 py-1 rounded hover:bg-gray-200"
                  onClick={() => {
                    navigate(`?page=${parseInt(myStar?.page) - 1}`)
                    onsubmit(parseInt(myStar?.page) - 1)
                  }}
                >
                  Previous
                </div>
              </li>
            ) : (
              <li className="mb-1 opacity-50">
                <div className="bg-gray-100 text-gray-500 px-3 py-1 rounded">Previous</div>
              </li>
            )}

            {[...Array(myStar?.totalPages).keys()].map(i => (
              <li key={i + 1} className={`mb-1 ${i + 1 === parseInt(myStar?.page) ? 'bg-blue-500 text-white' : ''}`}>
                <div
                  className={`cursor-pointer px-3 py-1 rounded ${i + 1 === parseInt(myStar?.page) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900 hover:bg-gray-200'}`}
                  onClick={() => {
                    navigate(`?page=${i + 1}`)
                    onsubmit(i + 1)
                  }}
                >
                  {i + 1}
                </div>
              </li>
            ))}

            {parseInt(myStar?.page) < myStar?.totalPages ? (
              <li className="mb-1">
                <div
                  className="cursor-pointer bg-gray-100 text-gray-900 px-3 py-1 rounded hover:bg-gray-200"
                  onClick={() => {
                    navigate(`?page=${parseInt(myStar?.page) + 1}`)
                    onsubmit(parseInt(myStar?.page) + 1)
                  }}
                >
                  Next
                </div>
              </li>
            ) : (
              <li className="mb-1 opacity-50">
                <div className="bg-gray-100 text-gray-500 px-3 py-1 rounded">Next</div>
              </li>
            )}
          </ul>
        </div>
      </section>
    </Wrapper>
  );
};

export default Company;
